.home{
    display: flex;
    width: 100%;
    height: 100vh;
    margin: 0px auto;
    /* max-width: 1280px; */
}   
.homeTitle{
    align-self: center;
    margin-top: 40vh;
    font-size: 3.2em;
    font-weight: bold;
    letter-spacing: -0.03em;
    line-height: 1.1;
}
.leftHome{
    background-color: #101820FF;
    float: left;
    margin: 0;
    padding: 0;
    height: 100vh; 
    width: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.rightHome{
  background-color: #101820FF;
  height: 100vh; 
  width: 50%;
  align-items: center;
  inset: 0;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
}
.leftTitle{
    font-size: 2.2rem;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 0px;
    align-self: first flex-start;
    margin-left: 10%;
    text-align: left;
    font-family: 'Manrope', sans-serif;
    margin-top: 0px;
}
.leftText{
    color: #fff;
    font-size: 22px;
    /* font-weight: bold; */
    letter-spacing: 1px;
    align-self: first flex-start;
    margin-left: 10%;
    margin-top: 0px;
    /* padding-left: 60px; */
    font-family: 'Manrope', sans-serif;
}
.workButton {
  border-radius: 5px;
  border-width: 0px;
  color: #fff;
  font-weight: bold;
  width: 25%;
  height: 30px;
  margin: 20px;
  margin-left: 10%;
  background-color: #B209AA;
  letter-spacing: 1px;
  cursor: pointer;
  z-index: 50;
  font-size: 0.7rem;
  text-decoration: none;  
  transition: border-color 0.5s, background-color 0.5s, color 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workButton:hover{
  background-color: #fff;
  border-color: transparent;
  color: #444;
}
/* .workButton:hover{
  animation: color_anim 1s infinite 0.2s;
} */
.profileImg{
    width: 280px;
    height: 340px;
    border-radius: 100px;
}
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.gradient {
    --size: 250px;
    --speed: 10s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    align-self: center;
    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 6));
    background-image: linear-gradient(hsla(246, 100%, 28%, 0.85), hsl(308, 91%, 42%));
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 50% 30% 50% 30% / 50% 30% 50% 30%;
    /* z-index: 990; */
    /* background-color: red; */
}
@media (min-width: 720px) {
    .gradient {
      --size: 350px;
      /* z-index: 900; */
    }
  }
  
  /* This is just to transition when you change the viewport size. */
  * {
    transition: all 0.25s ease-out;
  }
  

.avatarImg{
    --s: 280px; /* image size */
    --b: 5px; /* border thickness */
    --c: #ffffff; /* border color */
    --f: 1; /* initial scale */
    align-self: center;
    width: var(--s);
    aspect-ratio: 1;
    padding-top: calc(var(--s)/5);
    cursor: pointer;
    border-radius: 0 0 999px 999px;
    --_g: 50%/calc(100%/var(--f)) 100% no-repeat content-box;
    --_o: calc((1/var(--f) - 1)*var(--s)/2 - var(--b));
    outline: var(--b) solid var(--c);
    outline-offset: var(--_o);
    background: 
      radial-gradient(
        circle closest-side,
        #101820FF calc(99% - var(--b)),var(--c) calc(100% - var(--b)) 99%,#0000
       ) var(--_g);
    -webkit-mask:
      linear-gradient(#000 0 0) no-repeat
       50% calc(1px - var(--_o)) / calc(100%/var(--f) - 2*var(--b) - 2px) 50%,
      radial-gradient(circle closest-side,#000 99%,#0000) var(--_g);
    transform: scale(var(--f));
    transition: .5s;
    position: absolute;
  }
  .avatarImg:hover {
    --f: 1.35; /* hover scale */
  }
.animatedIcon{
  position: absolute;
  width: 30%;
  /* z-index: 999; */
}
.prText{
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    font-size: 2em;
    box-sizing: border-box;
    align-self: center;
    text-align: center;
}
.avatar{
    width: auto;
    height: 30%;
    align-self: center;
}
.myPhoto{
  width: auto;
  height: 20%;
  object-fit: cover;
  display: block;
  bottom: 0;
  margin: auto 0;
  margin-left: auto;
  margin-right: auto;
}
.socialIcons{
  display: flex;
  /* background-color: red; */
  width: 100%;
  height: 100px;
  align-self: first flex-start;
  margin-left: 10%;
  margin-top: 0px;
  justify-content: flex-start;
  z-index: 49;
}
.socialIcon{
  margin: 2%;
  cursor: pointer;
}
.socialIcon:hover{
  fill: #B209AA;
}
@media only screen and (max-width: 850px) {
    .home{
        flex-direction: column; 
        width: 100%;
    }
    .avatar {
        width: auto;
        height: 30%;
        align-self: center;
    }
    .workButton{
      /* display: block; */
      /* align-self: 'center' ; */
      /* float: inline-end; */
      /* align-self: flex-end; */
      width: 50%;
      /* margin-top: 4rem; */
      height: 50px;
      font-size: 1rem;
      /* margin: auto; */
      margin-left: auto;
      margin-right: auto;
      /* float: initial; */
      /* justify-content: center; */
    }
    .leftHome{
      /* display: flex; */
      /* margin: 0; */
      /* border: hidden; */
      display: flex;
      width: 100%;
      /* background-color: red; */
      flex-direction: column;
      height: 50%;
      padding-top: 100px;
      margin: 0;
      float: none;
      /* justify-content: center; */
      /* align-items: center; */
      /* align-content: center; */
      /* vertical-align: sub; */
    }
    .rightHome{
      width: 100%;
      /* background-color: blue; */
      margin: 0;
      height: 50%;
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex;
    }
    .animatedIcon{
      /* position: absolute; */
      width: auto;
      height: 50%;
      /* background-color: red; */
    }
    .leftTitle{
      font-size: 1.5rem;
      align-self: center;
      width: 100%;
      /* align-self: center; */
      margin: 0;

      /* background-color: blue; */
      text-align: center;
    }
    .leftText{
      margin: 0;
      text-align: center;
      font-size: 1.2rem;
      width: 90%;
      align-self: center;
      /* background-color: red; */
    }
    .socialIcons{
      align-items: center;
      justify-content: center;
      display: flex;
      /* background-color: red; */
      width: 100%;
      /* height: 100px; */
      /* align-self: first flex-start; */
      margin-left: 0;
      margin-top: 0px;
      /* justify-content: flex-start; */
      z-index: 900;
    }
}



html{
    scroll-behavior: smooth;
    z-index: 999;
}
  
body{
    margin: 0;
    z-index: 999;
}
.logo{
    /* margin-left: auto; */
    cursor: pointer;
    vertical-align: middle;
    width: 6rem;
    height: 6rem;
    /* right: 10%; */
    /* padding: 10px; */
    /* margin: 10px; */
    margin-left: 20%;
}
.navigation{
    display: flex;
    align-items: center;
    height: 80px;
    top: 0;
    width: 100%;
    padding: 0.6rem;
    background-color: transparent;
    /* justify-content: space-between; */
    color: rgb(255, 255, 255);
    /* font-weight: bold; */
    font-family: 'Manrope', sans-serif;
    position: fixed;
    transition: all .3s ease; 
    margin: 0;
    z-index: 999;
    /* background-color: red; */
}
.navigation.active{
    background: black;
    color: white;
    width: 100%;
    text-decoration: none;
    transition: all .3s ease; 
}
.staticNavigation{
    position: sticky;
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    font-family: 'Manrope', sans-serif;
    margin: 0;
    z-index: 999;
    align-items: center;
    padding: 0.5rem 0;
    top: 0;
    justify-content: space-between;
    height: 80px;
    background-color: white;
}
.navigation-menu {
    margin-left: auto;
    display: flex;
    justify-content: space-between;

}
.navigation-menu ul {
    display: flex;
    transition: all .6s ease; 
    padding: 0;
    margin: 0;
}
.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
    outline: none;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 10px;
    height: auto;
}
.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 1.2em;
    margin-right: 1em;
    
}
.item{
    font-weight: bold;
    color: inherit;
    width: 100%;
    margin: 0;
    height: auto;
    font-size: 1.3em;
    background-color: aqua;
    cursor: pointer;
    padding: 25px 15px;
    vertical-align: middle;
    text-decoration: none;
}
.mobileButton{
    border: 0;
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 20%;
    display: flex;
    transition: background-color 0.2s ease-in-out;
    display: none;
    align-content: center;
    justify-content: center;
    justify-items: center;
}
.mobileButton:hover{
    /* background-color: #101820; */

}
@media only screen and (max-width: 850px) {
    .mobileButton{
        display: block;
        z-index: 999;
    }
    .navigation-menu ul {
        position: fixed;
        left: 0;
        height: 100%;
        top: 0;
        width: 100%;
        background-color: #000000;
        display: none;
        transition: all .6s ease; 

    }
    .navigation-menu li {
        text-align: center;
        margin: 0;
        margin-top: 80px !important;
        margin-bottom: -20px !important;
      }
    
      .navigation-menu li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
      }
    
      .navigation-menu li:hover {
        background-color: #353535;
      }
    
      .navigation-menu.expanded ul {
        display: block;
        left: 0;
        top: 0;
      }
      .logo{
        margin: auto;
      }
}





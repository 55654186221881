.footer{
    bottom: 0;
    display: flex;
    width: 100%;
    height: auto;
    color: black;
    background-color: #101820FF;
    flex-direction: column;
}
.footerImg{
    width: auto;
    height: 9rem;
    object-fit: cover;
    margin-top: 2% !important;
    position: relative;
    margin: auto 0;
    margin-left: auto;
    margin-right: auto;
}
.footerText{
    color: white;
    align-self: center;
    font-size: 1.5em;
    margin-top: -10px;
    font-weight: lighter;
}
.legendText{
    color: white;
    align-self: center;
    font-size: 1em;
    margin-top: -10px;
    font-weight: lighter;
    color: rgb(102, 102, 102);
}
.iconContainer{
    height: 100px;
    align-self: center;
    margin: auto;
    position: relative;
    float: right;
}
.socialButton{
    cursor: pointer;
    margin: 0.5rem;
    /* float: left; */
    height: 1.5rem;
    width: auto;
    border-radius: 50%;
    /* border: 0.1rem solid #ffffff; */
    background-color: inherit;
    padding: 0.7rem;
}
.socialButton:hover{
    background-color: #b209aa73;

}
.secondContainer{
    background-color: #B209AA;
    border-radius: 10px;
    width: 80%;
    height: 6rem;
    margin-top: -3%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    flex-direction: row;
    display: flex;
}
.divisions{
    width: 100%;
    display: flex;
    height: auto;
    font-size: 2em;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Condensed', sans-serif;

}
.divisions p{
    font-weight: bold;
    color: #fff;
    width: 100%;
}
.divisions a{
    font-weight: bold;
    color: #fff;
    font-size: 1rem;
    padding: 10px;

}
.divisions button{
    background-color: transparent;
    width: 10rem;
    border-radius: 5px;
    border-color: rgb(255, 0, 0);
    color: #fff;
    font-stretch: expanded;
    cursor: pointer;
    border: 1px solid; 
    height: 3rem;
    transition: all .3s ease; 


}
.divisions button:hover{
    transition-delay:0.2s;
    background-color: #000;
    color: #ffff;
    border-color: black;
    text-align: center;
    vertical-align: center;
    transition: all .3s ease; 

}
@media only screen and (max-width: 850px) {
    .secondContainer{
        flex-direction: column;
        display: flex;
        height: 35%;
        margin-top: -20%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
    }
}
body{
    font-family: 'Manrope', sans-serif;
    /* z-index: 999; */
}
.about{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}
.aboutContent{
    height: 60vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}
.divA{
    width: 40%;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
}
.divB{
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.minDiv{
    content: ' ';
    background: #B209AA;
    width: 2rem; 
    height: 5px;
    border-radius: 5px;
    align-self: center;
}
.imgContainer{
    width: 100%;
    height: 100px;
}
.meImg{
    width: 300px;
    height: 300px;
    margin-right: 15%;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 15px 30px -10px #797979;
    overflow: hidden;
}
.abText{
    margin-top: 10%;
    margin-left: 20%;
    font-family: 'Manrope', sans-serif;
    font-size: 2.5em;
    color: #fff;
    letter-spacing: 2px;
    font-weight: bold;
    /* width: 80%; */
    /* background-color: red; */
    text-align: justify ;
}
.desText{
    /* align-self: center; */
    color: white;
    /* width: 100%; */
    margin-bottom: 10%;
    font-size: 1.3em;
    font-family: 'Manrope', sans-serif;
    /* width: 80%; */
    margin-bottom: 6%;
    margin-left: 20%;
    text-align: justify ;
}
.titleText{
    position: relative;

    text-align: center;
    align-self: center;
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 2rem;
}

.skillContainer{
    width: 80%;
    align-self: center;
    border-radius: 5px;
    margin-bottom: 5vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    align-items: center; 
    flex-direction: row;
    margin-top: 2vh;
    padding: 15px;
}
.skillItem {
    text-align: center;
    width: auto;
    margin: auto;
}
.skillItem:hover {
    filter: brightness(0.3); 
}
.skillText {
    color: #fff !important;
    font-size: 1em;
    margin-top: 5px;
}
.abFirst{
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
}
.abSecond{
   border-radius: 10px;
   right: 0;
   width: 50%;
   height: 100%;
   align-self: center;
}
.aboutForm{
    margin-top: 5%;
    width: 8%;
    margin-left: 9%;
}
.skillList{
    color: gray;
    font-size: 1.5em;
    font-weight: lighter;
    letter-spacing: -0.05em;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1;
}
.test{
    width: 80%;
    align-self: center;
    margin-left: 10%;
    margin-top: 20px;
}
.info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.info span{
    font-family: 'Roboto Condensed', sans-serif;
     font-weight: 800;
}
.test .line{
    height: 5px;
    width: 100%;
    background: lightgray;
    position: relative;
}
.test .line::before{
    content: "";
    height: 100%;
    width: 100%;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 0;
    background: crimson;
}
.test .line.html::before{
    width: 80%;
}
.test .line.java::before{
    width: 60%;
}
.test .line.js::before{
    width: 80%;
}
.test .line.react::before{
    width: 70%;
}
.test .line.php::before{
    width: 60%;
}
.test .line.node::before{
    width: 50%;
}
.test .line.vue::before{
    width: 50%;
}
.test .line.css::before{
    width: 70%;
}
.test .line.mysql::before{
    width: 70%;
}
.skillTitle{
    width: 90%;
    height: auto;
    margin: 20px;
    margin-top: 10%;
    font-weight: bold;
    text-align: start;
    letter-spacing: 2px;
}
.skillText{
    color: rgb(94, 94, 94);
    margin: 20px;
    letter-spacing: -0.8px;
    width: 90%;
    height: auto;
    text-align: left;
    font-size: 1.5rem;
    font-family: 'Roboto Condensed', sans-serif;

}
.textGrid{
    width: 100%;
    height: auto;
    margin: 0;
    text-align: left;
}
.cvButton{
    display: flex;
    justify-content: center;
    background-color: #000;
    color: #fff;
    margin-top: 0.2em;
    align-self: flex-start;
    margin-left: 20%;
    width: 12em;
    border-radius: 5px;
    height: 4em;
    border: 1px solid; 
    border-color: #fff;
    font-size: 0.8em;
    font-stretch:expanded;
    cursor: pointer;
    text-decoration: none;
    line-height:4em;
    transition: all .3s ease; 
}
.cvButton:hover{
    transition: all .3s ease; 
    background-color: #fff;
    color: #000;
    border-color: #fff;
    text-align: center;
    vertical-align: center;
}
@media only screen and (max-width: 850px) {
    .aboutContent{
        margin-bottom: 10%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .divA{
        width: 100% ;
        display: flex;
        height: auto;
        justify-content: center;
        text-align: center;
    }
    .divB{
        width: 100% ;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    .meImg{
        width: 200px;
        height: 200px;
        margin-left: 0%;
        align-self: center;
        margin: 0;
        /* height: auto; */
        border-radius: 50%;
        object-fit: cover;
        margin-top: 25px;

    }
    .abText{
        text-align: center ;
        width: 100%;
        align-self: center;
        margin: 0;
        margin-top: 25px;
    }
    .desText{
        text-align: center ;
        width: 100% ;
        align-self: center;
        margin: 0;
        margin-top: 25px;

    }
    .cvButton{
        align-self: center;
        margin: auto;
        margin-top: 25px;

    }
    .aboutForm{
        align-self: center;
        margin: 0;
        text-align: center;
        width: 10%; 
    }
    .textGrid{
        flex-direction: column;
        display: flex;
        text-align: center;
    }
    .titleText{
        /* padding: 20px; */
        /* font-size: 2.5rem; */
        /* position: relative; */
    }
}
@media only screen and (max-width: 850px) {
    .skillContainer{
        flex-direction: column;
        width: 100%;
        /* background-color: red; */
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }
    .desText{
        width: 100%;
    }
    .abFirst{
        flex-direction: column;
    }
    .abSecond{
        border-radius: 10px;
        width: 100%;
        height: 100%;
        align-self: center;
     }
     .skillTitle{
        text-align: center;
        margin: 0;
        font-size: 1.5rem;
        width: 100%;
     }
     .skillText{
        text-align: center;
        font-size: 1.2rem;
        margin: 0;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
     }
}
@media only screen and (orientation: landscape){
    .titleText{
        background-color: transparent;
        margin: 0;
        margin-top: 20px;
    }
    .titleText::after{
        background: black;
    }
}
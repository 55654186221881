body{
    font-family: 'Roboto Condensed', sans-serif;
}
.contactContainer{
    flex-direction: column;
    display: flex;
    width: 100%;
    margin: 0;
    height: 100%;
}
.textUp{
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 60%;
    box-sizing: border-box;
}
.textDown{
    display: flex;
    margin: auto;
    margin-bottom: 20px;
    width: 60%;
}
.submitButton{
    display: flex;
    justify-content: center;
    background-color: #ffff;
    color: black;
    margin-top: 0.2em;
    margin-bottom: 2em;
    align-self: center;
    width: 12em;
    border-radius: 5px;
    height: 4em;
    border: 1px solid; 
    border-color: lightgrey;
    font-size: 0.8em;
    font-stretch:expanded;
    cursor: pointer;
    text-decoration: none;
    line-height:4em;
    transition: all .3s ease; 
    align-self: center;
    margin: auto;
}
.submitButton:hover{
    transition: all .3s ease; 
    background-color: #000;
    color: #ffff;
    border-color: black;
    text-align: center;
    vertical-align: center;
}
.formTitle{
    width: 90%;
    align-self: center;
    text-align: center;
}
.dialogButton{
    text-decoration: none;
    border: 1px solid;
    border-radius: 3px;
    width: 10rem;
    height: auto;
    font-size: 1rem;
    cursor: pointer;
    color: #000;
    background-color: #fff;
}
.dialogButton:hover{
    background-color: #000;
    color: #fff;
}
@media only screen and (max-width: 850px) {
    .textUp{
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .textDown{
        justify-content: center;
        margin: auto;
        text-align: center;
        margin-bottom: 15px;
    }
    .formTitle{
        width: 80%;
        align-self: center;
        text-align: center;
    }
    
   
}
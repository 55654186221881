
.scroll{
    width: 100%;
    height: 100%;
}

.about{
    display: flex;
    width: 100%;
    height: auto;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
}
.myWork{
    display: flex;
    width: 100%;
    height: auto;
    color: black;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
}

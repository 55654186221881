body{
    font-family: 'Manrope', sans-serif;
}
.myWork{
    display: flex;
    width: 100%;
    height: auto;
    color: black;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
}
.rText{
    margin-top: 9%;
    display: block;
    font-size: 2em;
    box-sizing: border-box;
    align-self: center;
    text-align: center;
    color: white;
    font-weight: bold;
}
.wText{
    align-self: center;
    color: white;
    width: 70%;
    font-size: 1.3em;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 6%;

}
.emailMe{
    color: gray;
}
.wContainers{
    align-self: center;
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
@media only screen and (max-width: 650px) {
    .wContainers{
        flex-direction: column;
        display: flex;
    }
}
.workCard{
    height: 230px;
    border-radius: 10px;
    text-align: center;
    color: white;
    padding: 20px;
    margin: 10px;
    display: flex;
    position: relative;
    cursor: pointer;
}
.imgFondo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s ease;

}
.imgFondo:hover{
   background: white;
   -webkit-filter: blur(5px);
   transition: 1s ease;
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 1s ease;
    background-color: #000000;
    display: flex;
}
 .overlay:hover {
    opacity: 1;
    transition: 1s ease;

}
.textCard{
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    height: 50%;
    width: 100%;
    height: auto;
    align-self: center;
    position: absolute;
    font-size: 1.3rem;
    font-weight: 600;

}
.moreButton{
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 4em;
    margin-bottom: 4em;
    align-self: center;
    width: 20em;
    border-radius: 30px;
    height: 4em;
    border: 1px solid; 
    border-color: white;
    font-size: 1em;
    font-weight: bold;
    font-stretch:expanded;
    cursor: pointer;
    text-decoration: none;
    line-height:4em;
    transition: all .3s ease; 

}
.moreButton:hover{
    transition: all .3s ease; 
    background-color: white;
    color: black;
    border-color: black;
    text-align: center;
    vertical-align: center;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-5px);
    transition: all 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-5px);
  }
  .workCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  
  .myCard {
    width: 18rem;
  }
  
  .myCard .card-body {
    background-color: rgba(207, 207, 207, 0); 
    color: rgb(78, 78, 78);
  }
  
  .myCard .btn-primary {
    background-color: #007bff; 
    border: none; 
  }
  
  .myCard .btn-primary:hover {
    background-color: #0056b3;
  }
  